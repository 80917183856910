<template>
  <div>
    <div class="text-h6 black--text">
      {{ $t('tracking.title') }}
    </div>
    <tracker-card-integrate class="mt-4" />
    <tracker-card-voluum v-if="hasVisibleVoluum" class="mt-4" />
    <tracker-card-conversion class="mt-5" />
    <conversion-test header-is-dark class="mt-5" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import TrackerCardIntegrate from './TrackerCardIntegrate/Index.vue'
  import TrackerCardConversion from './TrackerCardConversion.vue'
  import ConversionTest from '@/components/ConversionTest/Index.vue'
  import TrackerCardVoluum from '@/views/Tracking/TrackerCardVoluum.vue'

  export default {
    name: 'Tracking',
    components: {
      TrackerCardVoluum,
      TrackerCardIntegrate,
      TrackerCardConversion,
      ConversionTest
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      hasVisibleVoluum() {
        return this.adNetwork.settings.visibleVoluum
      }
    }
  }
</script>
