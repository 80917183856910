import http from '../http.js'

export default {
  store: async () => {
    await http.post('/api/private/v1/voluum-api-token')
  },
  destroy: async ({ token }) => {
    await http.delete(`/api/private/v1/voluum-api-token/${token}`)
  }
}
