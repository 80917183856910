<template>
  <div>
    <v-expansion-panels v-model="panel" multiple :readonly="$vuetify.breakpoint.mdAndUp">
      <v-expansion-panel>
        <v-expansion-panel-header
          class="cursor-default white--text black text-subtitle-1 min-height-64"
        >
          {{ $t('tracking.integrate.title') }}
          <template #actions>
            <v-icon color="white" class="d-block d-sm-none">
              mdi-chevron-down
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text class="secondary-darken--text pt-4 pb-0 px-0">
            <span>
              {{ $t('tracking.integrate.description') }}
            </span>
            <trackers-list class="mt-2 mb-0" />
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import TrackersList from './TrackersList.vue'

  export default {
    name: 'TrackerCardIntegrate',
    components: { TrackersList },
    data() {
      return {
        panel: [0]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .min-height-64 {
    min-height: 64px;
  }
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
  .cursor-default {
    cursor: default;
  }
</style>
