<template>
  <v-row>
    <v-col
      v-for="(tracker, index) in trackers"
      :key="index"
      cols="6"
      sm="4"
      md="2"
      lg="1"
      class="pa-2 pa-sm-1"
    >
      <v-card
        v-if="tracker.url"
        class="card pa-4"
        color="secondary-lighten"
        :ripple="false"
        block
        flat
        @click="onTrackerClick(tracker)"
      >
        <v-img :src="tracker.imageSrc" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'TrackersList',
    computed: {
      ...mapGetters('settings', ['helpUrls']),
      trackers() {
        return [
          {
            name: 'VOLUUM',
            url: this.helpUrls.voluum,
            imageSrc: require('@/assets/tracking/voluum.svg'),
            gtmEventName: 'voluum'
          },
          {
            name: 'BeMob',
            url: this.helpUrls.bemob,
            imageSrc: require('@/assets/tracking/bemob.svg'),
            gtmEventName: 'bemob'
          },
          {
            name: 'PeerClick',
            url: this.helpUrls.peerclick,
            imageSrc: require('@/assets/tracking/peerclick.svg'),
            gtmEventName: 'peer_click'
          },
          {
            name: 'Binom',
            url: this.helpUrls.binom,
            imageSrc: require('@/assets/tracking/binom.svg'),
            gtmEventName: 'binom'
          },
          {
            name: 'AdsBridge',
            url: this.helpUrls.adsbridge,
            imageSrc: require('@/assets/tracking/adsbridge.svg'),
            gtmEventName: 'ads_bridge'
          },
          {
            name: 'RedTrack',
            url: this.helpUrls.redtrack,
            imageSrc: require('@/assets/tracking/redtrack.svg'),
            gtmEventName: 'red_track'
          },
          {
            name: 'Keitaro',
            url: this.helpUrls.keitaro,
            imageSrc: require('@/assets/tracking/keitaro.svg'),
            gtmEventName: 'keitaro'
          },
          {
            name: 'TriveTracker',
            url: this.helpUrls.thrive_tracker,
            imageSrc: require('@/assets/tracking/trivetracker.svg'),
            gtmEventName: 'trive_tracker'
          },
          {
            name: 'Kintura',
            url: this.helpUrls.kintura,
            imageSrc: require('@/assets/tracking/kintura.svg'),
            gtmEventName: 'kintura'
          },
          {
            name: 'FunnelFlux',
            url: this.helpUrls.funnelflux,
            imageSrc: require('@/assets/tracking/funnelflux.svg'),
            gtmEventName: 'funnel_flux'
          },
          {
            name: 'Trackwill',
            url: this.helpUrls.trackwill,
            imageSrc: require('@/assets/tracking/trackwill.svg'),
            gtmEventName: 'trackwill'
          },
          {
            name: 'Octotracker',
            url: this.helpUrls.octotracker,
            imageSrc: require('@/assets/tracking/octotracker.svg'),
            gtmEventName: 'octotracker'
          }
        ].filter(({ url }) => !!url)
      }
    },
    methods: {
      onTrackerClick(tracker) {
        gtmPush({
          event: GTM_EVENTS.TRACKING_INTEGRATION_ACTION,
          tracker_name: tracker.gtmEventName
        })
        window.open(tracker.url)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .card {
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 4px;

    &:active {
      border: 1px solid var(--v-primary-base) !important;
    }
    &:hover {
      border: 1px solid var(--v-secondary-base) !important;
    }
    &:focus::before {
      opacity: 0;
    }
  }
</style>
