<template>
  <v-expansion-panels v-model="panel" multiple :readonly="$vuetify.breakpoint.mdAndUp">
    <v-expansion-panel>
      <v-expansion-panel-header
        class="cursor-default white--text black text-subtitle-1 min-height-64"
      >
        {{ $t('tracking.voluum.title') }}
        <template #actions>
          <v-icon color="white" class="d-block d-sm-none">
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="mx-n1 mx-sm-0">
          <v-card-text class="secondary-darken--text pa-0 mt-5">
            <span v-if="!isMobile" class="black--text">
              {{ $t('tracking.voluum.description') }}
            </span>
            <v-row no-gutters align="center" class="mt-sm-3">
              <v-col
                cols="12"
                sm="2"
                lg="auto"
                class="mr-sm-5 mb-7 mb-sm-0"
              >
                <v-sheet
                  class="pa-5 pa-sm-4"
                  color="secondary-lighten"
                  rounded
                >
                  <v-img contain :height="isMobile ? 42 : 20" :src="voluum.imageSrc" />
                </v-sheet>
              </v-col>
              <v-col>
                <c-text-field
                  :value="token"
                  :placeholder="$t('tracking.voluum.generate_api_token')"
                  :label="isMobile ? $t('tracking.voluum.description') : ''"
                  label-bold
                  height="46"
                  hide-details
                  readonly
                >
                  <template v-if="token" #append>
                    <copy-button :text-copied="token" />
                    <c-btn
                      icon
                      color="error"
                      :loading="removeTokenIsLoading"
                      :icon-props="{
                        icon: '$close',
                        size: '18'
                      }"
                      @click="$refs.removeAcceptDialog.ask(token)"
                    />
                  </template>
                </c-text-field>
              </v-col>
              <v-col v-if="!token" :cols="isMobile ? 12 : 'auto'" class="pl-sm-5 mt-3 mt-sm-0">
                <c-btn
                  color="primary"
                  :block="isMobile"
                  :height="46"
                  :loading="createTokenIsLoading"
                  :label="$t('tracking.popup.generate_new_token')"
                  depressed
                  @click="createToken()"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <confirm-dialog ref="removeAcceptDialog" @confirm="removeToken()">
          <template #title>
            {{ $t('tracking.popup.delete_token') }}
          </template>
          <template #body>
            {{ $t('tracking.popup.delete_sure') }}
          </template>
        </confirm-dialog>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CopyButton from '@/components/CopyButton.vue'
  import ConfirmDialog from '@/components/ConfirmDialog.vue'
  import voluumTokenRepository from '@/services/repositories/voluum-token-repository.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'TrackerCardVoluum',
    components: {
      ConfirmDialog,
      CBtn,
      CopyButton,
      CTextField
    },
    data() {
      return {
        createTokenIsLoading: false,
        removeTokenIsLoading: false,
        panel: [0],
        voluum: {
          name: 'VOLUUM',
          imageSrc: require('@/assets/tracking/voluum.svg')
        }
      }
    },
    computed: {
      token() {
        return this.$auth.user?.voluum_api_token ?? ''
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    methods: {
      async createToken() {
        this.createTokenIsLoading = true
        try {
          await voluumTokenRepository.store()
          await this.refreshProfile()
        } catch (error) {
          handleErrors(error)
        }
        this.createTokenIsLoading = false
      },
      async removeToken() {
        this.removeTokenIsLoading = true
        try {
          await voluumTokenRepository.destroy(this.token)
          await this.refreshProfile()
        } catch (error) {
          handleErrors(error)
        }
        this.removeTokenIsLoading = false
      },
      async refreshProfile() {
        await this.$auth.fetchUser()
      }
    }
  }
</script>

<style lang="scss" scoped>
.cursor-default {
  cursor: default;
}
</style>
