<template>
  <v-tooltip v-model="isShown" close-delay="3000" top color="white" :disabled="!isShown">
    <template #activator="{ on, attrs }">
      <c-btn
        icon
        v-bind="attrs"
        class="black--text"
        :icon-props="{
          icon: '$copy',
          size: 18,
          color: 'primary'
        }"
        v-on="on"
        @click="copy(textCopied)"
        @mouseover="isShown = false"
      />
    </template>
    <span class="text-caption black--text">
      {{ $t('main.content_copy') }}
    </span>
  </v-tooltip>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'CopyButton',
    components: {
      CBtn
    },
    props: {
      textCopied: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isShown: false
      }
    },
    methods: {
      copy(text) {
        this.$copy(text)
        this.isShown = true
      }
    }
  }
</script>
