<template>
  <v-card elevation="0">
    <v-card-title class="text-subtitle-1 white--text black mb-5">
      {{ $t('tracking.conversion.title') }}
    </v-card-title>

    <v-card-text class="secondary-darken--text">
      <c-text-field
        :value="postbackURL"
        class="my-2"
        readonly
        label-bold
        hide-details
        :label="$t('tracking.conversion.your_postback_url')"
        background-color="white"
        border-color="secondary"
        height="46"
      >
        <template #append>
          <copy-button :text-copied="postbackURL" />
        </template>
      </c-text-field>

      <v-alert color="info" text type="info" icon="mdi-information-outline">
        {{ $t('tracking.conversion.copy_postback_url') }}
        <br />
        {{ $t('tracking.conversion.required_params') }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CopyButton from '@/components/CopyButton.vue'

  export default {
    name: 'TrackerCardConversion',
    components: {
      CopyButton,
      CTextField
    },
    computed: {
      postbackURL() {
        return this.$auth.user.postback_endpoint ?? ''
      }
    }
  }
</script>
